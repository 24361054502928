import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { IENVIRONMENT_SERVICE, IEnvironmentService } from '../environment/environment.interface';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  private endpointApi = '';
  constructor(private http: HttpClient, @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService) {
    this.endpointApi = this.envService.getVar('services.api') as string;
  }

  public getData<T = any>(
    settings: Partial<{
      widget: string;
      from: string;
      to: string;
      settings: Record<string, any>;
    }>
  ): Observable<T> {
    return this.http.post<any>(`${this.endpointApi}/widget/data`, settings).pipe(pluck('data'));
  }
}
